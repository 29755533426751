import { createMachine, assign, createActor } from 'xstate';
import { SaveCart } from '../api/index';
import caomeiImg from '../assets/products/caomi.jpg';
import xihongshiImg from '../assets/products/xhs.png';
import xiguaImg from '../assets/products/xigua.png';
import doujiaoImg from '../assets/products/doujiao.png';
import dongguaImg from '../assets/products/donghua.png';
import huacaiImg from '../assets/products/huacai.png';
import xilanhuaImg from '../assets/products/xlh.png';
import putaoImg from '../assets/products/putao.png';
// import Card from '@mui/material/Card';
// import Box from '@mui/material/Box';
// import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import { useMachine } from '@xstate/react';
const updateCart = (cart: any) => {
  SaveCart(JSON.stringify(cart));
}

export const appMachine: any = createMachine({
    id: 'woo-life',
    initial: sessionStorage.getItem('out_trade_no') ? 'payment' : 'home',
    context: {
      orderViewType: null, // 待支付 待收货
      path: 0,
      currentAddressObj: null,
      goods: [
          // { id: 1, name: '普罗旺斯西红柿',searchList: ['番茄'], price: 2.19, marke: '单个一斤左右'/*备注 */, weight: 300, priceUnit: 'g'/* 重量单位 */,  image: xihongshiImg, feature: ['免配送费', '新鲜采摘'], favorite: true, stock: 0 },
          // { id: 2, name: '草莓', searchList: [''], price: 5.3, marke: '单个半斤左右', weight: 199, priceUnit: 'g', image: caomeiImg, feature: ['免配送费', '新鲜采摘'], favorite: false, stock: 0},
          // { id: 3, name: '8424西瓜', searchList: [''], price: 4.32, marke: '',weight: 205, priceUnit: 'g', image: xiguaImg, feature: ['免配送费', '新鲜采摘'], favorite: true, stock: 0 },
          // { id: 4, name: '鲜豆角', searchList: [''], price: 8.20, marke: '',weight: 333, priceUnit: 'g', image: doujiaoImg, feature: ['免配送费', '新鲜采摘'], favorite: false, stock: 0 },
          // { id: 5, name: '冬瓜', searchList: [''], price: 1.55, marke: '',weight: 155, priceUnit: 'g', image: dongguaImg, feature: ['免配送费', '新鲜采摘'], favorite: true, stock: 0 },
          // { id: 6, name: '花菜', searchList: [''], price: 9.02, marke: '',weight: 503, priceUnit: 'g', image: huacaiImg, feature: ['免配送费', '新鲜采摘'], favorite: false, stock: 0 },
          // { id: 7, name: '西蓝花',searchList: [''], price: 0.33, marke: '',weight: 444, priceUnit: 'g',image: xilanhuaImg, feature: ['免配送费', '新鲜采摘'], favorite: false, stock: 0 },
          // { id: 8, name: '葡萄', searchList: [''], price: 6.58, marke: '', weight: 678, priceUnit: 'g', image: putaoImg, feature: ['免配送费', '新鲜采摘'], favorite: true, stock: 0 },
      ],
      cart: [],
      order: {
        waiting: [], // 待支付
        claim: [], // 待收货
        finished: [], // 已完成
      },
      chat: {
        log: [],
        unread: 0,
        receiver_unread: 0
      },
      detailId: -1,
      user: {},
      OrderDetail: undefined
    },
    states: {
      home: {
        on: {
          add: {
            actions: 'addToCart'
          },
          cart: { target: 'cart'},
          profile: { target: 'profile'},
          detail: { target: 'detail', actions: assign({
            detailId: ({ event }): number => {
              return event.data.id;
            }
          }) },
          payment: { target: 'payment' },
          goods: {
            actions: assign({
              // @ts-ignore 
              goods: ({ context, event }) => {
                context.goods = event.value;
                return event.value as any[];
              }
            })
          },
          msg: { target: 'msg' }
        },
        meta: { desc: '生鲜' },
      },
      cart: {
        initial: 'list',
        meta: { desc: '购物车'},
        on: {
          profile: { target: 'profile'},
          home: { target: 'home'},
          toggle: {
            actions: assign({
              cart: ({ context, event}) => {
                context.cart.forEach((item: any) => {
                  if (item.id === event.id) {
                    item.checked = !item.checked;
                  }
                });
                return [ ...context.cart ] as [];
              }
            })
          },
          increase: {
            actions: assign({
              cart: ({ context, event}) => {
                const cns: any = [context.cart.find((ele: any) => ele.id === event.id)];
                updateCart([ ...context.cart, ...cns ]);
                return [ ...context.cart, ...cns ] as [];
              }
            })
          },
          decrease: {
            actions: assign({
              cart: ({ context, event }) => {
                const left = [];
                while (true) {
                  const cns: any = context.cart.shift();
                  if (cns.id === event.id) {
                    break;
                  }
                  left.push(cns);
                }
                updateCart([ ...left, ...context.cart ]);
                return [ ...left, ...context.cart ] as [];
              }
            })
          },
          delete: {
            actions: assign({
              cart: ({ context, event}) => {
                updateCart([...context.cart.filter((item: any) => item.id !== event.id)]);
                return [...context.cart.filter((item: any) => item.id !== event.id)] as []
              }
            })
          },
          wait: {
            actions: assign({
              order: ({ context, event}) => {
                return { ...context.order, wait:  event.value };
              }
            })
          },
          payment: { target: '#woo-life.payment'},
          msg: { target: 'msg' }
        },
        states: {
          list: {
            on: {
              confirm: { target: 'confirm' },
              // @ts-ignore
              cancel: {
                actions: assign({
                  cart: ({ context, event}) => {
                    context.cart.forEach((item: any) => item.checked = false)
                    return [ ...context.cart ];
                  }
                })
              },
              checked: {
                actions: assign({
                  cart: ({ context, event}) => {
                    context.cart.forEach((item: any) => item.checked = true)
                    return [ ...context.cart ];
                  }
                })
              },
              clear: { actions: 'clearCart' },
            }
          }, // 购物车列表页
          confirm: {
            meta: { desc: '确认订单页面' },
            on: {
              list: { target: 'list' },
              clear: { actions: 'clearCart' },
              select_addr: { target: '#woo-life.profile.select_addr' }
            },
            exit: ({ context, event}) => {
              context.currentAddressObj = null;
            }
          }
        }
      },
      payment: {
        on: {
          home: { target: '#woo-life.home' },
          order: { target: '#woo-life.profile.order' },
          msg: { target: 'msg' }
        }
      },
      profile: {  
        initial: 'home',
        meta: { desc: '我的' },
        on: {
          cart: { target: 'cart'},
          home: { target: 'home'},
          msg: { target: 'msg' },
        },
        states: {
          home: {
            on: {
              order: { target: '#woo-life.profile.order', actions: 'setViewType' },
              login: { actions: 'login' },
              address: { target: '#woo-life.profile.address' },
              score_exchange: { target: '#woo-life.profile.score_exchange' },
            }
          },
          order: {
            on: {
              home: '#woo-life.profile.home',
              detail: {
                target: '#woo-life.profile.detail',
                actions: 'updateOrderDetail'
              },
              login: { actions: 'login' },
              order: '#woo-life.profile.order',
              setViewType: { actions: 'setViewType'} 
            },
          },
          detail: {
            on: {
              order: '#woo-life.profile.order',
              login: { actions: 'login' },
            }
          },
          address: {
            on: {
              home: {
                target: '#woo-life.profile.home',
              },
              login: { actions: 'login' },
            }
          },
          select_addr: {
            on: {
              update_addr: {
                target: '#woo-life.cart.confirm',
                actions: ({ context, event}) => {
                  context.currentAddressObj = event.value;
                }
              },
              login: { actions: 'login' },
              home: { target: '#woo-life.cart.confirm' },
            }
          },
          score_exchange: {

          }
        }
      },
      detail: {
        on: {
          home: { target: 'home'},
          add:  {
            actions: 'addToCart'
          },
          msg: { target: 'msg' }
        },
      },
      msg: {
        initial: 'list',
        states: {
          list: {},
          chat: {}
        },
        on: {
          home: { target: 'home'},
          cart: { target: 'cart'},
          profile: { target: 'profile'},
          payment: { target: 'payment'},
          chat: { target: 'msg.chat' },
          list: { target: 'msg.list' }
        }
      }
    },
    on: {
      message: {
        actions: 'updateMessage'
      },
      init_cart: {
        actions: 'initCart'
      }
    }
}, {
  actions: {
    updateOrderDetail: assign({
      OrderDetail: ({context, event}) => {
        context.OrderDetail = event.value;
        return event.value as any;
      }
    }),
    addToCart: assign({
      // @ts-ignore 
      cart: ({ context, event }) => {
        const cns: any = (context as any).goods.find((ele: any) => ele.id === event.id);
        cns.checked = true;
        updateCart([...context.cart, cns]);
        return [ ...context.cart, cns ] as any[];
      }
    }),
    clearCart: assign({
      cart: () => {
        updateCart([]);
        return [];
      }
    }),
    initCart: assign({
      cart: ({ context, event}) =>  event.value
    }),
    login: assign({
      user: ({ context, event }) => {
        context.user = event.value;
        return event.value as any
      }
    }),
    // 设置当前查看订单的类型，如待支付，待收货
    setViewType: assign({
      orderViewType: ({ context, event }) => {
        context.orderViewType = event.value;
        return event.value as any
      }
    }),
    updateMessage: assign({
      chat: ({ context, event}) => {
        context.chat.unread = event.value?.chat?.unread ?? 0;
        context.chat.receiver_unread = event.value?.chat?.receiver_unread ?? 0;
        context.chat.log =  event.value?.chat?.log ?? [];
        return { unread: context.chat.unread, receiver_unread: context.chat.receiver_unread, log: context.chat.log };
      }
    }),
  }
});

  
